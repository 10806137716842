*,
*:before,
*:after {
	box-sizing: border-box;
}


/*------------------------------------------------------------------------*/
/*  Body
/*------------------------------------------------------------------------*/

body {
	text-align: left;
	color: white;
	font-family: $source_serif;
	font-size: 15px;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: url('../images/bg-body.png') left top repeat;
}


/*------------------------------------------------------------------------*/
/*  Images
/*------------------------------------------------------------------------*/

img {
	max-width: 100%;
	height: auto;
}

img.alignleft,
img.alignright {
	max-width: 48%;
}


/*------------------------------------------------------------------------*/
/*  Selection
/*------------------------------------------------------------------------*/

@include selection {
	color: white;
	text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
	background: rgba($orange, 0.6);
}

input,
textarea,
button,
select,
a {
	-webkit-tap-highlight-color: rgba($orange, 0.6);
}


/*------------------------------------------------------------------------*/
/*  Lists
/*------------------------------------------------------------------------*/

#content ul {
	padding: 0;
	margin: 0 0 20px 20px;

	li {
		list-style: disc;
		padding: 0;
		margin: 0 0 0 10px;
		text-align: left;
	}

	&:last-child {
		margin-bottom: 0;
	}

}

#content ol {
	padding: 0;
	margin: 0 0 20px 20px;

	li {
		list-style-type: decimal;
		padding: 0;
		margin: 0 0 0 18px;
	}

	&:last-child {
		margin-bottom: 0;
	}

}


/**
* @Wrapper
*
*/

#wrapper {
	max-width: $wrap;
	margin: 70px auto 40px auto;
	padding: 0 18px;
	position: relative;
	height: auto;
	z-index: 4;

	@include media($large) {
		margin: 6% auto 40px auto;
	}

}

/**
* @Content
*
*/

#panorama {
	display: block;

	&:before {
		content: "";
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: transparent;
		box-shadow: inset 0px 0px 60px rgba(0,0,0,0.8);
	}

	.panorama-img--desktop {
		display: none;

		@include media($large) {
			display: block;
		}

	}

	.panorama-img--tablet {
		display: none;

		@include media($extra-small) {
			display: block;
		}

		@include media($large) {
			display: none;
		}

	}

	.panorama-img--mobile {
		display: block;

		@include media($extra-small) {
			display: none;
		}

	}

	img {
		display: block;
		width: 100%;
		height: auto;
	}

}

#content {
	position: relative;
	@include clearfix;

	#overlay {
		background: $brown_mobile;
		position: relative;
		z-index: 10;
		width: 100%;
		height: auto;
		position: relative;

		@include media($large) {
			float: right;
			width: 920px;
			height: 640px;
			background: url('../images/bg-overlay-new.png') left top no-repeat;
		}

		.wrap {
			padding: 30px;

			@include media($large) {
				padding: 60px 40px 60px 180px;
			}

		}

	}

	#background-image {
		position: relative;

		@include media($large) {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			background-size: cover;
			background-position: center top;
			background-repeat: no-repeat;
		}

		&:before {

			@include media($large) {
				content: "";
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: transparent;
				box-shadow: inset 0px 0px 60px rgba(0,0,0,0.8);
			}

		}

		&:after {
			content: "";
			position: absolute;
			bottom: -1px;
			left: 0;
			width: 100%;
			padding-bottom: 8%;
			background: url('../images/bg-mask.png') right top no-repeat;
			background-size: cover;

			@include media($large) {
				display: none;
			}

		}

		img {
			width: 100%;
			height: auto;
			display: block;

			@include media($large) {
				display: none;
			}

		}

	}

	#scrollable {
		position: relative;

		@include media($large) {
			height: 510px;
			overflow: auto;
			overflow-x: hidden;
		}

		.scroll-wrap {


			@include media($small) {
				padding-right: 20px;
			}

		}

	}

}

/**
* @Recipes
*
*/

#recipes {
	width: 100%;
	overflow: hidden;

	// .col {
	// 	margin-bottom: 16px;
	//
	// 	a {
	// 		display: block;
	// 		position: relative;
	//
	// 		h3 {
	// 			background: $orange;
	// 			position: absolute;
	// 			bottom: 0;
	// 			left: 0;
	// 			width: 100%;
	// 			line-height: 1.0;
	// 			color: white;
	// 			font-size: 12px;
	// 			margin: 0;
	// 			padding: 10px 8px;
	// 			opacity: 0;
	// 			transition: all 0.3s ease;
	// 		}
	//
	// 		img {
	// 			display: block;
	// 			border: 4px solid $brown_light;
	// 			box-shadow: 0px 0px 4px rgba(0,0,0,0.3);
	// 			transition: all 0.3s ease;
	// 		}
	//
	// 		&:hover {
	//
	// 			h3 {
	// 				opacity: 1;
	// 			}
	//
	// 			img {
	// 				border-color: $orange;
	// 			}
	//
	// 		}
	//
	// 	}
	//
	// }

	.recipe {
		width: 100%;
		margin-bottom: 20px;

		@include media($extra-small) {
			@include span-columns(6);
			@include omega(2n);
			margin-bottom: 16px;
		}

		@include media($medium) {
			@include omega-reset(2n);
			@include span-columns(3);
			@include omega(4n);
		}

		a {
			display: block;
			position: relative;
			cursor: pointer;

			h3 {
				background: $orange;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				line-height: 1.0;
				color: white;
				font-size: 12px;
				margin: 0;
				padding: 10px 8px;
				opacity: 0;
				transition: all 0.3s ease;
			}

			img {
				width: 100%;
				display: block;
				border: 4px solid $brown_light;
				box-shadow: 0px 0px 4px rgba(0,0,0,0.3);
				transition: all 0.3s ease;
			}

			&:hover {

				h3 {
					opacity: 1;
				}

				img {
					border-color: $orange;
				}

			}

		}

	}

}

.recipe-content {
	width: 100%;

	@include media($small) {
		float: left;
		width: 65%; /* 420/700 */
	}

	.button {
		display: none !important;

		@include media($small) {
			display: inline-block !important;
		}

	}

}

.recipe-info {
	width: 100%;

	@include media($small) {
		float: right;
		width: 30%; /* 180/700 */
	}

	.info-wrap {
		padding: 18px;
		color: $brown_lighter;
		border-radius: 5px;
		background: darken($brown_dark, 1%);
		margin-bottom: 20px;
	}

	img {
		display: block;
		border: 4px solid $brown_light;
		box-shadow: 0px 0px 4px rgba(0,0,0,0.3);
		margin-bottom: 15px;
	}

}
