/*------------------------------------------------------------------------*/
/*  Footer
/*------------------------------------------------------------------------*/

#footer {
	position: relative;
	width: 100%;
	height: auto;
	@include clearfix;

	ul {
		float: left;
		@include clearfix;

		li {
			float: left;
			margin-top: 15px;
			margin-right: 16px;

			a {
				color: $brown_light;
				text-transform: uppercase;
				text-decoration: none;
				font-family: $source_serif;

				&:hover {
					color: $brown;
				}

			}

		}

	}

}
