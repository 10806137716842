/*------------------------------------------------------------------------*/
/*  Header
/*------------------------------------------------------------------------*/

#logo {
	width: 140px;
	float: right;
	position: absolute;
	top: -54px;
	right: 24px;
	z-index: 100;

	@include media($large) {
		width: 180px;
		top: -65px;
		right: 24px;
	}

	a {
		display: block;

		img {
			transition: all 0.3s ease;
		}

		&:hover {

			img {
				transform: translateY(-8px);
			}

		}

	}

}

#navigation {
	position: relative;
	width: 100%;
	background: url('../images/bg-navi.jpg') left top repeat;
	box-shadow: inset 0px 0px 4px rgba(0,0,0,0.4);
	margin-bottom: 10px;

	.navigation-wrap {

		&.is-active {

			ul {
				max-height: 400px;
			}

		}

	}

	ul {
		width: 100%;
		max-height: 0px;
		overflow: hidden;
		transition: max-height .8s linear;
		@include clearfix;

		@include media($medium) {
			max-height: 400px;
		}

		li {
			display: block;
			border-bottom: 1px solid #b1916f;

			@include media($medium) {
				float: left;
				border-bottom: 0px;
			}

			a {
				display: block;
				text-decoration: none;
				color: white;
				font-size: 16px;
				line-height: 1.0;
				padding: 12px 12px 10px 12px;
				font-family: $source_serif;
				text-transform: uppercase;

				@include media($medium) {
					padding: 10px 12px 8px 12px;
					display: inline-block;
				}

				@include media($large) {
					padding-left: 16px;
					padding-right: 16px;
				}

				&:hover {
					color: $brown;
				}

			}

      &.active,
			&.current-menu-item,
      &.current-post-parent,
      &.current-post-ancestor {

				a {
					color: $brown;
				}

			}

		}

	}

	.navigation-toggle {
		display: block;
		position: relative;
		color: white;
		font-size: 18px;
		line-height: 1.0;
		padding: 15px 12px 12px 48px;
		font-family: $source_serif;
		text-transform: uppercase;
		text-decoration: none;
		border-bottom: 1px solid #b1916f;

		@include media($medium) {
			display: none;
		}

	}

}

/* -----------------------------------------------------------------------------
  HAMBURGER ICONS COMPONENT
----------------------------------------------------------------------------- */

$button-width: 30px;                    // The width of the button area
$button-height: 30px;                   // The height of the button area
$bar-thickness: 2px;                    // The thickness of the button bars
$button-pad: 8px;                      // The left/right padding between button area and bars.
$button-bar-space: 2;                   // The spacing between button bars
$button-transistion-duration: 0.25s;    // The transition duration

.hamburger-icon {
  display: block;
	position: absolute;
	top: 8px;
	left: 8px;
	float: left;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: $button-width;
  height: $button-height;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: inset 0px -1px 1px rgba(0,0,0,0.3);
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: background $button-transistion-duration, top .5s ease-in-out;
	background: $orange;
	background: -moz-linear-gradient(top, $orange 0%, $orange_dark 100%);
	background: -webkit-linear-gradient(top, $orange 0%, $orange_dark 100%);
	background: linear-gradient(to bottom, $orange 0%, $orange_dark 100%);

  &:focus {
    outline: none;
  }

  span {
    display: block;
    position: absolute;
    top: ($button-height / 2) - ($bar-thickness / 2);
    left: $button-pad;
    right: $button-pad;
    height: $bar-thickness;
    background: $brown;
    border-radius: $bar-thickness;
    transition: background 0s $button-transistion-duration;
  }

  span::before,
  span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: $bar-thickness;
    background: $brown;
    content: "";
    border-radius: $bar-thickness;
    transition-duration: $button-transistion-duration, $button-transistion-duration;
    transition-delay: $button-transistion-duration, 0s;
  }

  span::before {
    top: -$bar-thickness - $button-bar-space;
    transition-property: top, transform;
  }

  span::after {
    bottom: -$bar-thickness - $button-bar-space;
    transition-property: bottom, transform;
  }

  /* active state, i.e. menu open */

  &.is-active {

    span {
      background: none;

      &:before {
        top: 0;
        transform: rotate(45deg);
      }

      &:after {
        bottom: 0;
        transform: rotate(-45deg);
      }

      &:before,
      &:after {
        transition-delay: 0s, $button-transistion-duration;
      }

    }

  }

}
