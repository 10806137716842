/*------------------------------------------------------------------------*/
/*  @font-face
/*------------------------------------------------------------------------*/

/*
@font-face {
font-family: 'Source Sans';
src: url('../fonts/sourcesanspro-light-webfont.eot');
src: url('../fonts/sourcesanspro-light-webfont.eot?#iefix') format('embedded-opentype'),
     url('../fonts/sourcesanspro-light-webfont.woff2') format('woff2'),
     url('../fonts/sourcesanspro-light-webfont.woff') format('woff'),
     url('../fonts/sourcesanspro-light-webfont.ttf') format('truetype'),
     url('../fonts/sourcesanspro-light-webfont.svg#source_sans_prolight') format('svg');
font-weight: normal;
font-style: normal;
}
*/

@font-face {
font-family: 'Source Sans';
src: url('../fonts/sourcesanspro-regular-webfont.eot');
src: url('../fonts/sourcesanspro-regular-webfont.eot?#iefix') format('embedded-opentype'),
     url('../fonts/sourcesanspro-regular-webfont.woff2') format('woff2'),
     url('../fonts/sourcesanspro-regular-webfont.woff') format('woff'),
     url('../fonts/sourcesanspro-regular-webfont.ttf') format('truetype'),
     url('../fonts/sourcesanspro-regular-webfont.svg#source_sans_proregular') format('svg');
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Source Serif';
src: url('../fonts/sourceserifpro-regular-webfont.eot');
src: url('../fonts/sourceserifpro-regular-webfont.eot?#iefix') format('embedded-opentype'),
     url('../fonts/sourceserifpro-regular-webfont.woff2') format('woff2'),
     url('../fonts/sourceserifpro-regular-webfont.woff') format('woff'),
     url('../fonts/sourceserifpro-regular-webfont.ttf') format('truetype'),
     url('../fonts/sourceserifpro-regular-webfont.svg#source_serif_proregular') format('svg');
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Source Serif';
src: url('../fonts/SourceSerifPro-Semibold.eot');
src: url('../fonts/SourceSerifPro-Semibold.eot?#iefix') format('embedded-opentype'),
	 url('../fonts/SourceSerifPro-Semibold.woff2') format('woff2'),
	 url('../fonts/SourceSerifPro-Semibold.woff') format('woff'),
	 url('../fonts/SourceSerifPro-Semibold.ttf') format('truetype'),
	 url('../fonts/SourceSerifPro-Semibold.svg#SourceSerifPro-Semibold') format('svg');
font-weight: bold;
font-style: normal;
}

// SVG font on -webkit- browsers
@media screen and (-webkit-min-device-pixel-ratio:0) {

	@font-face {
	font-family: 'Source Sans';
	src: url('../fonts/sourcesanspro-light-webfont.svg#source_sans_prolight') format('svg');
	font-weight: normal;
	font-style: normal;
	}

	@font-face {
	font-family: 'Source Sans';
	src: url('../fonts/sourcesanspro-regular-webfont.svg#source_sans_proregular') format('svg');
	font-weight: bold;
	font-style: normal;
	}

	@font-face {
	font-family: 'Source Serif';
	src: url('../fonts/sourceserifpro-regular-webfont.svg#source_serif_proregular') format('svg');
	font-weight: normal;
	font-style: normal;
	}

}


/*------------------------------------------------------------------------*/
/*  Headings
/*------------------------------------------------------------------------*/

h1,
h2,
h3,
h4,
h5 {
	text-decoration: none;
	font-family: $source_serif;
	font-weight: normal;
	color: $orange;
	line-height: 1.2;
	text-align: left;
}

h1 {
	font-size: 26px;
	margin: 0 0 20px 0;
}

h2, h2 a {
	font-size: 22px;
	line-height: 1.2;
	color: $brown_lighter;
	text-decoration: none;
	margin: 0 0 25px 0;

  br {
    display: none;

    @include media($small) {
  		display: block;
  	}

  }

}

h3, h3 a {
	font-weight: bold;
	font-size: 20px;
	margin-bottom: 10px;
	text-decoration: none;
}

h4, h5, h6 {
	font-weight: bold;
	font-size: 18px;
	font-family: $source_sans;
	margin-bottom: 10px;
}


/*------------------------------------------------------------------------*/
/*  Paragraphs
/*------------------------------------------------------------------------*/

p {
	margin-bottom: 15px;
}

blockquote {
	color: $orange;
	font-family: $source_sans;
	font-size: 18px;
	border-left: 5px solid $orange;
	margin-bottom: 20px;
	padding: 10px 20px;

	p {
		margin: 0;
		padding: 0;
	}

}


/*------------------------------------------------------------------------*/
/*  Links
/*------------------------------------------------------------------------*/

a {
	color: $orange;
	text-decoration: underline;
	@include transition(color .25s ease);

	&:hover {
		color: $orange;
	}

}


/*------------------------------------------------------------------------*/
/*  Usefull text classes
/*------------------------------------------------------------------------*/

.screen-reader-text {
	display: none;
}


/*------------------------------------------------------------------------*/
/*  Buttons
/*------------------------------------------------------------------------*/

#wrapper .button,
#wrapper .button-primary {
	display: inline-block;
	padding: 14px 18px 12px 18px;
	margin: 0 0 20px 0;
	color: white;
	font-size: 14px;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	text-decoration: none;
	text-transform: uppercase;
	line-height: 1.0;
	cursor: pointer;
	border: none;
	letter-spacing: 0.5px;
	font-family: $source_sans;
	background-color: $orange;
	border-radius: 5px;
	outline-style: none;
	@include transition ( all 0.3s );
	@include appearance(none);

	&:hover,
	&:focus {
		box-shadow: 0px 2px 2px rgba(0,0,0,0.2);
		background-color: $brown_light;
		text-decoration: none;
	}


}
