
// Variables

$source_sans: 	'Source Sans', Arial, sans-serif;
$source_serif:	'Source Serif', Georgia, sans-serif;

$orange: 		       #f5841e;
$orange_dark: 	   #e84f13;
$brown:			       #4d2d05;
$brown_light:	     #a18268;
$brown_lighter:	   #e6dacc;
$brown_dark:	     #3c3228;
$grey: 			       #444444;
$brown_mobile:     #403630;

$twitter:			        #00ACED;
$facebook:		        #3B5998;
$youtube:			        #D14451;

$mobile-font-size: 	  12px;
$tablet-font-size: 	  13px;
$desktop-font-size:   15px;

$wrap: 				1476px; // 1440 + 36
$wrap-x: 			1440px;
$wrap-y: 			540px;

/*------------------------------------------------------------------------*/
/*  Bourbon Neat breakpoints
/*------------------------------------------------------------------------*/

$extra-small:           new-breakpoint(min-width 480px);    // Extra small screen / phone
$small:                 new-breakpoint(min-width 576px);    // Small screen / phone
$medium:                new-breakpoint(min-width 768px);    // Medium screen / tablet
$large:                 new-breakpoint(min-width 992px);    // Large screen / desktop
$extra-large:           new-breakpoint(min-width 1170px);   // Extra large screen / wide desktop


/*------------------------------------------------------------------------*/
/*  Mixins
/*------------------------------------------------------------------------*/

@mixin clearfix {
    zoom:1;
    &:before,
    &:after {
        content: "\0020";
        display: block;
        height: 0;
        overflow: hidden;
    }
    &:after {
        clear: both;
    }
}

@mixin opacity($opacity) {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{round($opacity * 100)})";
	opacity: $opacity;
}

@mixin selection {
    ::-moz-selection { @content; }
    ::selection { @content; }
}

@mixin z {
    @include transform(translateZ(0));
}

@mixin retina($image, $width, $height) {
    @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    // on retina, use image that's scaled by 2
    background-image: url($image);
    background-size: $width $height;
    }
}

@mixin inputs {

    input[type=text],
    input[type=url],
    input[type=email],
    input[type=tel],
    input[type=number],
    input[type=password],
    textarea {
        @content;
    }

}

@mixin triangle ($position, $width, $height, $background){
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;

  @if $position == 'top' {

    border-width: 0 $width/2 $height $width/2;
    border-color: transparent transparent $background transparent;

   } @elseif $position == 'right' {

    border-width: $height/2 0 $height/2 $width;
    border-color: transparent transparent transparent $background;

   } @elseif $position == 'bottom' {

    border-width: $height $width/2 0 $width/2;
    border-color: $background transparent transparent transparent;

   } @elseif $position == 'left' {

    border-width: $height/2 $width $height/2 0;
    border-color: transparent $background transparent transparent;

   } else {
      display: none;
   }

}

@mixin col($col, $gutter: 4%) {
    width: ( 100% - ( ($col - 1) * $gutter ) ) / $col;
    margin-right: $gutter;
}

@mixin last {
	margin-right: 0;

	+ * {
		clear: left;
	}
}


/*------------------------------------------------------------------------*/
/*  Functions
/*------------------------------------------------------------------------*/

@function em($px, $base: $desktop-font-size) {
    @return ($px / $base) * 1em;
}

@function p($px, $parent: $wrap) {
    @return ($px / $parent) * 100%;
}


// ------------------------------------------------------------
// Omega Reset
// ------------------------------------------------------------

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}
