/*------------------------------------------------------------------------*/
/*  Grid / Columns
/*------------------------------------------------------------------------*/

.clear {
	clear: both;
}

.col {
	position: relative;
}

// 2 columns

#content .cols-2 {

	.col {
		@include span-columns(6);
		@include omega(2n);
	}

}

// 3 columns

#content .cols-3 {

	.col {
		@include span-columns(4);
		@include omega(3n);
	}

}

// 4 columns

#content .cols-4 {

	.col {
		@include span-columns(3);
		@include omega(4n);
	}

}

// 6 columns

#content .cols-6 {

	.col {
		@include span-columns(2);
		@include omega(6n);
	}

}